<template>
   <section class="content-container">
      <router-view/>
   </section>
</template>

<script>

export default {
   data() {
      return {
         title: "Usuarios",
      };
   }};
</script>